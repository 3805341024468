import "./App.css";
import MainCard from "./components/MainCard";
import ReactGA from "react-ga4";
function App() {
  ReactGA.initialize("G-2TXC272HEH");
  ReactGA.send({ hitType: "pageview", page: "/", title: "Resume" });
  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
      <MainCard />
    </main>
  );
}

export default App;
